import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Terraform のテスト環境を Terraform Workspaces で構築した",
  "date": "2020-07-31T07:59:05.000Z",
  "slug": "entry/2020/07/31/165905",
  "tags": ["medley"],
  "hero": "./2020_07_31.png",
  "heroAlt": "teraform"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`株式会社メドレーのエンジニアの笹塚です。
主にジョブメドレーのインフラを担当しています。`}</p>
    <ul>
      <li parentName="ul">{`直近では、コンテナ化されていなかった環境の移行などをしていました。`}</li>
      <li parentName="ul">{`休日は主にゲームをやっています。今は、日本語版がリリースされたばかりの「レムナント：フロム・ジ・アッシュ」に夢中です。`}</li>
    </ul>
    <p>{`今回は `}<a parentName="p" {...{
        "href": "https://www.terraform.io/"
      }}>{`Terraform`}</a>{` のテスト環境を、`}<a parentName="p" {...{
        "href": "https://www.terraform.io/docs/state/workspaces.html"
      }}>{`Terraform Workspaces`}</a>{` を使用して構築した事例を紹介します。`}</p>
    <h1>{`背景`}</h1>
    <p>{`ジョブメドレーにはいくつかのサービスがあり、Terraform によるコード化が進んでいるサービスと、Ansible、 itamae などで部分的にコード化はされているものの、Terraform の使用が遅れているサービスが混在しており、これらのサービスについても Terraform への移行を進めています。`}</p>
    <p>{`Terraform への移行とあわせて、メンテナンスを担当するメンバーを増やす必要があるのですが`}</p>
    <p>{`【作業担当】`}</p>
    <ul>
      <li parentName="ul">{`Terraform のコードから、実際に稼働させる環境を作るのは慣れていても難しい。`}</li>
    </ul>
    <p>{`【レビュワー】`}</p>
    <ul>
      <li parentName="ul">{`Terraform のコードの差分だけで、内容をすぐに把握するのは難しい。`}</li>
    </ul>
    <p>{`などの理由から、作業担当、レビュワーともにハードルが低いとは言えず、Terraform によるインフラの変更内容を事前に確認できる環境構築が必要だと感じるようになりました。`}</p>
    <h1>{`検討内容`}</h1>
    <p>{`事前に確認できる環境を作るにあたり、まず最初に検討したのは、各ステージのコードを共通化することでした。
ジョブメドレーの関連サービスでは、大きくわけて 3 つのステージで構成しています。`}</p>
    <p>{`Sandbox(個人の検証用) → QA(リリース前の検証用) → Production`}</p>
    <p>{`この各ステージのコードを共通化できれば、Production には QA 環境までで確認済みのコードを apply することができます。`}</p>
    <p>{`ですが、Sandbox 環境、QA 環境、Production 環境はそれぞれ似てはいるものの、全てが同じ構成ではありません。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200731/20200731151334.png",
      "alt": "20200731151334.png"
    }}></img>
    <p>{`Terraform の HCL では`}</p>
    <ul>
      <li parentName="ul">{`if 構文がない( resource の作成を count で制御することはできる)`}</li>
      <li parentName="ul">{`module 単位でのリソース作成の制御ができない(Terraform 0.13 から `}<a parentName="li" {...{
          "href": "https://github.com/hashicorp/terraform/tree/guide-v0.13-beta/module-repetition"
        }}>{`module でも count や for_each が可能になる`}</a>{`ようです)`}</li>
    </ul>
    <p>{`などの制限があり、構造の差分をコードで吸収しにくく、共通化できたとしてもメンテナンス性を下げる可能性が高いです。
また、すでに Terraform でコード化されている状態からの移行作業も楽ではないでしょう。`}</p>
    <p>{`そこで、ステージごとのコードを共通化するのではなく、`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/organizations/"
      }}>{`AWS Organizations`}</a>{` で別アカウントを作り、各ステージのコードを試せる環境を作ることにしました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200731/20200731151544.png",
      "alt": "20200731151544.png"
    }}></img>
    <p>{`<目標とする>`}</p>
    <p>{`Terraform のコードをプロダクションアカウントで実行する前に、テストアカウントで実行し、設定した内容を AWS マネジメントコンソール や AWS CLI で確認することできる。`}</p>
    <ul>
      <li parentName="ul">{`テストアカウント上で設定を確認した後、プロダクションアカウントに同じコードを apply することができる。`}</li>
      <li parentName="ul">{`コストを抑えるため、テストアカウント上のリソースは、確認が終了したら destory することができる。`}</li>
    </ul>
    <p>{`<目標としない>`}</p>
    <ul>
      <li parentName="ul">{`テストアカウント上でサービスが稼働することは目標としない。`}</li>
      <li parentName="ul">{`必要なデータセットの作成など用意するものが増えるので、目標には含めませんでした。`}</li>
    </ul>
    <h1>{`設定作業`}</h1>
    <p>{`必要な作業は大きくわけて 2 つです。`}</p>
    <ol>
      <li parentName="ol">{`Terraform Workspaces の設定`}</li>
      <li parentName="ol">{`アカウントごとに必要なコードの追加、修正`}</li>
    </ol>
    <h2>{`Terraform Workspaces の設定`}</h2>
    <p>{`Terraform と AWS provider の設定を変更することで、workspace を切り替えることができます。`}</p>
    <p>{`以下が作業イメージです。`}</p>
    <h3>{`workspace の追加`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`$ terraform workspace new production
$ terraform workspace list
  default
* production`}</code></pre></div>
    <h3>{`Terraform の環境変更`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "hcl"
    }}><pre parentName="div" {...{
        "className": "language-hcl"
      }}><code parentName="pre" {...{
          "className": "language-hcl"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`terraform`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
 `}<span parentName="code" {...{
            "className": "token property"
          }}>{`required_version`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"= 0.12.28"`}</span>{`
 `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`backend`}<span parentName="span" {...{
              "className": "token type variable"
            }}>{` "s3" `}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
   `}<span parentName="code" {...{
            "className": "token property"
          }}>{`bucket`}</span>{`               `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"example-state"`}</span>{`
   `}<span parentName="code" {...{
            "className": "token property"
          }}>{`workspace_key_prefix`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"workspace"`}</span>{`
   `}<span parentName="code" {...{
            "className": "token property"
          }}>{`dynamodb_table`}</span>{`       `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"terraform-state-lock"`}</span>{`
   `}<span parentName="code" {...{
            "className": "token property"
          }}>{`key`}</span>{`                   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"example.tfstate"`}</span>{`
   `}<span parentName="code" {...{
            "className": "token property"
          }}>{`region`}</span>{`               `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ap-northeast-1"`}</span>{`
   `}<span parentName="code" {...{
            "className": "token property"
          }}>{`profile`}</span>{`              `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"production"`}</span>{`
 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`provider`}<span parentName="span" {...{
              "className": "token type variable"
            }}>{` "aws" `}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
 `}<span parentName="code" {...{
            "className": "token property"
          }}>{`region`}</span>{`  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ap-northeast-1"`}</span>{`
 `}<span parentName="code" {...{
            "className": "token property"
          }}>{`version`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"= 2.70.0"`}</span>{`
 `}<span parentName="code" {...{
            "className": "token property"
          }}>{`profile`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` var.workspace_profile`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`terraform.workspace`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`variable`}<span parentName="span" {...{
              "className": "token type variable"
            }}>{` "workspace_profile" `}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
 `}<span parentName="code" {...{
            "className": "token property"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` map(string)
 `}<span parentName="code" {...{
            "className": "token property"
          }}>{`default`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
   `}<span parentName="code" {...{
            "className": "token property"
          }}>{`default`}</span>{`    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"test"`}</span>{`
   `}<span parentName="code" {...{
            "className": "token property"
          }}>{`production`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"production"`}</span>{`
 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`この例では、default workspace はテスト環境、Production を実際にサービスが稼働する環境のアカウントになるように設定しています。
それぞれ default は、aws config の test profile、Production は production profile を参照しています。`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`s3://example-state/example.tfstate`}</code>{` がテスト環境、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`s3://example-state/workspace/production/example.tfstate`}</code>{` が、プロダクション環境の state ファイルになります。`}</p>
    <p>{`Terraform のコード内からは、現在の workspace 名を terraform.workspace で参照することができます。`}</p>
    <p>{`ここまでの設定で`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`$ terraform workspace select [workspace 名] `}</code></p>
    <p>{`で workspace を切り替えることができるようになりました。`}</p>
    <p>{`あとは、アカウントごとに必要な変更をしていきます。`}</p>
    <h2>{`アカウントをまたいだ共通のリソースの定義`}</h2>
    <p>{`全アカウントでユニークにする必要があるリソース(S3 bucket、DNS など)の場合は、名称の分岐処理が必要です。
テストアカウントでは、リソース名に prefix をつけて作成するようにしました。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "hcl"
    }}><pre parentName="div" {...{
        "className": "language-hcl"
      }}><code parentName="pre" {...{
          "className": "language-hcl"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# 定義`}</span>{`
 `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`variable`}<span parentName="span" {...{
              "className": "token type variable"
            }}>{` "example_bucket_name" `}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
 `}<span parentName="code" {...{
            "className": "token property"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` map(string)
 `}<span parentName="code" {...{
            "className": "token property"
          }}>{`default`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
   `}<span parentName="code" {...{
            "className": "token property"
          }}>{`default`}</span>{`    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"test-example-bucket"`}</span>{`
   `}<span parentName="code" {...{
            "className": "token property"
          }}>{`production`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"example-bucket"`}</span>{`
 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# リソースでの参照時`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`resource `}<span parentName="span" {...{
              "className": "token type variable"
            }}>{`"aws_s3_bucket"`}</span></span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"example"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
 `}<span parentName="code" {...{
            "className": "token property"
          }}>{`bucket`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` var.example_bucket_name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`terraform.workspace`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
 ..
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`テストアカウントで起動するインスタンスタイプや台数の変更`}</h2>
    <p>{`テストアカウントでは EC2 のインスタンスを起動させなくても良い場合には、台数を変更するようにしました。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "hcl"
    }}><pre parentName="div" {...{
        "className": "language-hcl"
      }}><code parentName="pre" {...{
          "className": "language-hcl"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`resource `}<span parentName="span" {...{
              "className": "token type variable"
            }}>{`"aws_autoscaling_group"`}</span></span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"example_web"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`name`}</span>{`             `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"example-web"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`max_size`}</span>{`         `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`min_size`}</span>{`         `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`desired_capacity`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` terraform.workspace `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"production"`}</span>{` ? `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span>{`  : `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
  …
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`インスタンスの起動が必要な場合も、同様の分岐でインスタンスタイプの変更を行っています。`}</p>
    <h2>{`コード化をしないリソースの扱い`}</h2>
    <p>{`プロダクションアカウントで一旦コード化を保留したリソースについては data source で参照しますが、テストアカウントにはリソースが存在しないため作成しなければいけません。
テストアカウントのリソースは確認が終了した段階で destroy したいので`}</p>
    <ul>
      <li parentName="ul">{`ステージ用の Terraform コードとは別に、必要なリソースを作成するコードを用意する`}</li>
      <li parentName="ul">{`必要なリソース用のコード → ステージ用のコードの順に apply する`}</li>
    </ul>
    <p>{`ようにしました。
data source で参照できれば良い範囲でのコード化なので、この追加のリソースも最小限のコストになるようにしています。`}</p>
    <p>{`以上の設定で、同じ Terraform のコードを workspace を切り替えて plan、apply ができるようになりました。`}</p>
    <h1>{`運用してみて`}</h1>
    <p>{`プロダクションアカウントに apply する前に、テストアカウントで事前に apply することができるようになったので、作業中の試行錯誤もしやすくなりました。`}</p>
    <p>{`レビュワーも、テストアカウント上で実際に apply された結果を確認することができるようになり、apply の差分だけではわかりにくかった変更内容を確認できるようになりました。`}</p>
    <p>{`これなら新しく担当するメンバーの不安を、少しかもしれませんが解消できそうです。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`今回は Terraform のテスト環境を、Terraform Workspaces を使用して構築した事例を紹介させていただきました。
テストアカウント上のリソースの自動 destroy や、 plan、apply の自動化については触れられていませんが、また別の機会に紹介できればと思います。`}</p>
    <p>{`長らく運用しているサービスでは、サービスを稼働させたまま解決しなければいけない課題が数多くあります。
それらの課題を、一つずつ着実に解決していくことに楽しさを見いだせる方、ぜひメドレーで一緒に働きましょう！`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      